import React, { useState, useEffect } from "react";
import Firebase from "../../firebase";
import { noimageurl } from "../../generalfunc";
import LazyLoad from "react-lazyload";

const Mainpage = () => {
  const [listAds, setlistAds] = useState();
  const [image, setimage] = useState();

  useEffect(() => {
    //    Firebase.register("name", "haluk@gmail.com", "123456", "phone", "place");
    //  return;
    Firebase.GetAdsForMainPage().then(
      (result) => {
        setlistAds(result);
        console.log("result", result);
      },
      (error) => {}
    );
  }, []);

  function something() {
    console.log("enter");
  }

  return (
    <div id="content">
      <div className="container-fluid p-1">
        <div className="row row-cols-1 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-8 row-cols-xxl-10  my-2">
          {listAds
            ? listAds.map((ads, i) => {
                //Firebase.GetImageUrl(ads.images[0]).then((result) => {
                //                  setimage(result);
                // });
                return (
                  <LazyLoad once={ads} key={i} debounce={500} height={100} once>
                    <a href={"/adsdetail/" + ads.slug + "/" + ads.id} key={i}>
                      <div className="col mb-2" key={i}>
                        <div className="card card-main">
                          <img
                            src={ads.images[0] ? ads.images[0] : noimageurl()}
                            className="card-img-top"
                            alt={ads.desc}
                          />
                          <div className="card-body-main">
                            <h5 className="card-title-main">
                              {ads.title.substring(0, 40)}...
                            </h5>
                          </div>
                        </div>
                      </div>
                    </a>
                  </LazyLoad>
                );
              })
            : ""}
        </div>
      </div>
    </div>
  );
};

export default Mainpage;
