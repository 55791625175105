import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./components/auth/login";
import Logout from "./components/auth/logout";
import ForgotPass from "./components/auth/forgot";
import Register from "./components/auth/register";
import User from "./components/auth/user";
import Profile from "./components/auth/profile";
import ChangePassword from "./components/auth/changepassword";
import Authorization from "./components/auth/authorization";
import Addads from "./components/ads/addads";
import Editads from "./components/ads/editads";
import ListUserAds from "./components/ads/listuserads";
import ListSearchAds from "./components/ads/listsearchads";
import ListAdminAds from "./components/ads/listadminads";
import AdsDetail from "./components/ads/adsdetail";
import Header from "./components/general/header";
import Footer from "./components/general/footer";
import Sidebar from "./components/general/sidebar";
import Mainpage from "./components/general/mainpage";
import ListCategoryAds from "./components/ads/listcategoryads";
import SendMessage from "./components/message/sendmessage";
import MyMessage from "./components/message/mymessage";
import ImportData from "./import/importdata";
import Sitemap from "./components/general/sitemap.xml";

function App() {
  return (
    <Router>
      <Header />
      <div className="wrapper">
        <Sidebar />
        <Switch>
          <Route path="/category/:category" component={ListCategoryAds} />
          <Route path="/login/" component={Login} />
          <Route path="/logout/" component={Logout} />
          <Route path="/forgot/" component={ForgotPass} />
          <Route path="/SendMessage/" component={SendMessage} />
          <Route path="/mymessage/:id" component={MyMessage} />
          <Route path="/mymessage/" component={MyMessage} />
          <Route path="/register/" component={Register} />
          <Route path="/user/" component={User} />
          <Route path="/myprofile/" component={Profile} />
          <Route path="/changepassword/" component={ChangePassword} />
          <Route path="/import/" component={ImportData} />
          {/*
          <Route path="/Addads/" component={Authorization(Addads, "Addads")} />
*/}
          <Route path="/Addads/" component={Addads} />
          <Route path="/adsdetail/:slug/:id" component={AdsDetail} />
          <Route path="/editads/:id" component={Editads} />
          <Route path="/listuserads/" component={ListUserAds} />
          <Route path="/listsearchads/:search" component={ListSearchAds} />
          <Route path="/sitemap" component={Sitemap} />

          <Route
            path="/admin/"
            component={Authorization(ListAdminAds, "ListAdminAds")}
          />
          <Route path="/" component={Mainpage} />
        </Switch>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
