import React from "react";
import parameter from "../../data/parameter.json";

const Sidebar = () => {
  const Category = parameter.Category;

  return (
    <div id="sidebar">
      <ul>
        {Category.map((cat, i) => {
          return (
            <a href={"/category/" + cat._id} key={i}>
              <li className="list-group-item">
                <img src={cat.image} alt="category" className="img-fluid" />
                {"  "}
                {cat.name}
              </li>
            </a>
          );
        })}
      </ul>
      <a
        href="https://play.google.com/store/apps/details?id=com.PlusCrea.app"
        target="_blanch"
      >
        <img
          src={`${process.env.PUBLIC_URL}/img/kabakabamobile.png`}
          className="img-fluid"
        />
      </a>
    </div>
  );
};

export default Sidebar;
