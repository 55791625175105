import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Firebase from "../../firebase";
import * as firebase from "firebase/app";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, seterror] = useState("");
  let history = useHistory();

  /*
  async function logout() {
    await Firebase.logout();
  }
*/

  function loginwithgoogle(e) {
    e.preventDefault();

    var provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().languageCode = "fr_FR";
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        // ...

        Firebase.registerforSocial(
          user.displayName,
          user.email,
          user.photoURL,
          user.uid
        );

        console.log("google user", user);
        history.push("/myprofile/");
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
      });

    /*
    await Firebase.loginwithgoogle().then((result) => {
      console.log("googleresult", result);
      history.push("/myprofile/");
    });
    */
  }

  function loginwithfacebook(e) {
    e.preventDefault();
    var provider = new firebase.auth.FacebookAuthProvider();
    firebase.auth().languageCode = "fr_FR";
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        Firebase.registerforSocial(
          user.displayName,
          user.email,
          user.photoURL,
          user.uid
        );

        console.log("facebook user", user);
        history.push("/myprofile/");
        // ...
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
      });
    /*
    Firebase.loginwithfacebook().then(() => {
      history.push("/myprofile/");
    });
    */
  }

  async function login() {
    if (email === "") {
      seterror("Email");
      return;
    }
    if (password === "") {
      seterror("Password");
      return;
    }

    if (password.length < 6) {
      seterror("Password en az 6 olmalı");
      return;
    }
    try {
      var loginresult = await Firebase.login(email, password);
      //localStorage.setItem("userToken", JSON.stringify(loginresult));
      //props.history.replace("/dashboard");
      history.push("/listuserads");

      //setName(Firebase.getCurrentUsername());
    } catch (error) {
      seterror(error.message);
    }
  }

  return (
    <div id="content">
      <div className="container-fluid">
        <br />
        <div className="loginsignup p-3">
          <h2 className="text-center pb-1">L o g i n</h2>
          <div className="loginbox-social">
            <div className="row">
              <ul className="social-btns">
                <li>
                  <button
                    className="btn btn-facebook"
                    onClick={loginwithfacebook}
                  >
                    <i
                      className="fab fa-facebook pull-left"
                      aria-hidden="true"
                    ></i>
                    Se connecter via Facebook
                  </button>
                </li>
                {/*
                <li>
                  <button class="btn btn-twitter">
                    <i class="fab fa-twitter pull-left" aria-hidden="true"></i>
                    Se connecter via Twitter
                  </button>
                </li>
                */}
                <li>
                  <button className="btn btn-google" onClick={loginwithgoogle}>
                    <i
                      className="fab fa-google-plus pull-left"
                      aria-hidden="true"
                    ></i>
                    Se connecter via Google
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <form onSubmit={(e) => e.preventDefault()}>
            {error !== "" ? (
              <div class="alert alert-danger" role="alert">
                {error}
              </div>
            ) : (
              ""
            )}
            <div className="form-group">
              <label>E-mail</label>
              <input
                type="email"
                className="form-control"
                name="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Mot de passe</label>
              <input
                type="password"
                className="form-control"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button type="submit" className="btn btn-danger" onClick={login}>
              Connexion
            </button>
          </form>
          <div className="login text-center">
            <a href="/forgot">Mot de passe oublié?</a> |{" "}
            <a href="/register">Pas de compte? Inscrivez-vous.</a>
          </div>
        </div>

        <br />
      </div>
    </div>
  );
};

export default Login;
