import React, { useState, useEffect } from "react";
import Firebase from "../../firebase";

const SendMessage = (props) => {
  const { title, createDate, place, category, price, useremail } = props.ads;
  console.log("props", props);
  const adsid = props.adsid;
  const [message, setMessage] = useState("");
  const [isopen, setIsopen] = useState();

  const [success, setsuccess] = useState("");
  var User1Email = Firebase.getTokenEmailExpiry();
  var User2Email = useremail;
  var User1Name = "";
  var User2Name = "";

  useEffect(() => {
    setIsopen(props.show);
    Firebase.getUserDetailFromMail(User2Email).then((result) => {
      //console.log(result[0].displayName);
      User2Name = result[0].displayName;
    });
    Firebase.getUserDetailFromMail(User1Email).then((result) => {
      //console.log(result[0].displayName);
      User1Name = result[0].displayName;
    });
  }, []);

  useEffect(() => {
    if (!isopen) {
      return null;
    }
  }, [isopen]);

  function onRegister(e) {
    e.preventDefault();

    if (message !== "") {
      const newConversation = {
        User1Id: User1Email,
        User1Name: User1Name,
        User2Id: User2Email,
        User2Name: User2Name,
        LastDate: Date.now(),
        users: [User1Email, User2Email],
        message: [
          {
            author: User1Email,
            body: message,
            createDate: Date.now(),
            adsid: adsid,
            title: title,
          },
        ],
      };

      Firebase.AddConversation(newConversation);
    }
    /*
    const newConversation = {
      User1Id: "cccc",
      User1Name: "aaa",
      User2Id: "aaaa",
      User2Name: "ccc",
      message: [
        {
          author: "aaaa",
          body: message,
          createDate: Date.now(),
          adsid: adsid,
          title: title,
        },
      ],
    };
*/
    try {
      //Firebase.UpdateUserProfileWithId(name, place, phone);
      //history.push("/myprofile");
      setsuccess("processus terminé....");
    } catch (error) {
      //seterror(error.message);
    }

    //history.push(`/adsdetail/${adsid}`);
    //    setIsopen(false);
    //Firebase.AddConversation(newConversation);
    //props.onClose();
    //window.close();
    //window.opener = null;
  }
  return (
    <div>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Envoye Message
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            {success !== "" ? (
              <div className="modal-body">
                <div className="alert alert-success" role="alert">
                  {success}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Fermé
                  </button>
                </div>
              </div>
            ) : (
              <form onSubmit={onRegister}>
                <div className="modal-body">
                  <div className="form-group">
                    <label htmlFor="recipient-name" className="col-form-label">
                      Recipient:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="recipient-name"
                      defaultValue={title}
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="message-text" className="col-form-label">
                      Message:
                    </label>
                    <textarea
                      className="form-control"
                      name="message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Fermé
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Envoyer Message
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendMessage;
