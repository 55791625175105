import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Firebase from "../../firebase";
import parameter from "../../data/parameter.json";
import UserComboBox from "../general/combobox";
import * as firebase from "firebase/app";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repassword, setRepassword] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [place, setPlace] = useState("");
  const [error, seterror] = useState("");
  let history = useHistory();
  const Place = parameter.Place;
  //console.log(Mark);

  function loginwithgoogle(e) {
    e.preventDefault();
    var provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().languageCode = "fr_FR";
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        // ...

        Firebase.registerforSocial(
          user.displayName,
          user.email,
          user.photoURL,
          user.uid
        );

        console.log("google user", user);
        history.push("/myprofile/");
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
      });
    /*
    await Firebase.loginwithgoogle().then((result) => {
      console.log("googleresult", result);
      history.push("/myprofile/");
    });
    */
  }

  function loginwithfacebook(e) {
    e.preventDefault();
    var provider = new firebase.auth.FacebookAuthProvider();
    firebase.auth().languageCode = "fr_FR";
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        Firebase.registerforSocial(
          user.displayName,
          user.email,
          user.photoURL,
          user.uid
        );

        console.log("facebook user", user);
        history.push("/myprofile/");
        // ...
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
      });
    /*
    Firebase.loginwithfacebook().then(() => {
      history.push("/myprofile/");
    });
    */
  }

  async function register() {
    if (
      email === "" ||
      password === "" ||
      phone === "" ||
      place === "" ||
      name === ""
    ) {
      seterror("Remplissez tous les champs");
      return;
    }
    if (password.length < 6) {
      seterror("Le mot de passe doit être d'au moins 6");
      return;
    }
    if (password !== repassword) {
      seterror("Les mots de passe ne sont pas égaux");
      return;
    }

    try {
      await Firebase.register(name, email, password, phone, place);
      history.push("/");
    } catch (error) {
      //alert(error.message);
      seterror(error.message);
    }
  }

  return (
    <div id="content">
      <div className="container-fluid">
        <br />
        <div className="loginsignup p-3">
          <h2 className="text-center pb-1">Inscription</h2>
          <div className="loginbox-social">
            <div class="row">
              <ul class="social-btns">
                <li>
                  <button class="btn btn-facebook" onClick={loginwithfacebook}>
                    <i class="fab fa-facebook pull-left" aria-hidden="true"></i>
                    Se connecter via Facebook
                  </button>
                </li>

                <li>
                  <button class="btn btn-google" onClick={loginwithgoogle}>
                    <i
                      class="fab fa-google-plus pull-left"
                      aria-hidden="true"
                    ></i>
                    Se connecter via Google
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <form onSubmit={(e) => e.preventDefault()}>
            {error !== "" ? (
              <div class="alert alert-danger" role="alert">
                {error}
              </div>
            ) : (
              ""
            )}
            <div className="form-group">
              <label>Nom</label>
              <input
                type="text"
                className="form-control"
                name="name"
                autoFocus
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>E-mail</label>
              <input
                type="email"
                className="form-control"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Mot de passe</label>
              <input
                type="password"
                className="form-control"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Mot de passe encore</label>
              <input
                type="password"
                className="form-control"
                name="repassword"
                value={repassword}
                onChange={(e) => setRepassword(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Téléphone de contact</label>
              <input
                type="text"
                className="form-control"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="form-group">
              <UserComboBox
                title="Ville"
                datas={Place}
                name="place"
                value={place}
                handleChange={(e) => setPlace(e.target.value)}
              />
            </div>
            <button type="submit" className="btn btn-danger" onClick={register}>
              S'Inscrire
            </button>
          </form>
        </div>
        <br />
      </div>
    </div>
  );
};

export default Register;
