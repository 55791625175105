import React, { useState, useEffect } from "react";
import Firebase from "../../firebase";
import { RoleInUserTypes } from "./admin";

const Authorization = (Page, PageName) => {
  const [email, setEmail] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    var mail = Firebase.getTokenEmailExpiry();

    setEmail(mail);
    console.log(PageName);
  }, []);

  useEffect(() => {
    var admin = RoleInUserTypes["SuperAdmin"].includes(email);
    console.log(admin);
    setIsAdmin(admin);
    console.log(email);
  }, [email]);

  return class WithAuthorization extends React.Component {
    render() {
      if (email === "" || email === null)
        return (
          <div id="content">
            <div className="container-fluid text-center">
              <br></br>
              <br></br>
              <img src="../img/Stop-Logo.png"></img>
              <h3>You must be login</h3>
              <br></br>
              <br></br>
              <div className="login">
                <a href="/login">Login</a> | <a href="/register">Register</a>
              </div>
              <br></br>
              <br></br>
            </div>
          </div>
        );
      else if (email != "" && isAdmin && PageName === "ListAdminAds")
        return <Page {...this.props} />;
      else if (email != "" && PageName != "ListAdminAds")
        return <Page {...this.props} />;
      else
        return (
          <div>
            <h3></h3>
          </div>
        );
    }
  };
  /*
  return class WithAuthorization extends React.Component {
    render() {
      if (email === "")
        return (
          <div>
            <h3>You must be login</h3>
          </div>
        );
      if (Page === "ListAdminAds" && isAdmin) return <Page {...this.props} />;
      return (
        <div>
          <h3>{email}</h3>
        </div>
      );
    }
    };
    */
};

export default Authorization;
