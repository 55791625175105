import React, { useEffect, useState } from "react";
import parameter from "./resimler.json";
import postparam from "./postdata.json";
import userparam from "./users.json";
import Firebase from "../firebase";

export default function ImportData() {
  const images = parameter.Images;
  const post = postparam.post;
  const users = userparam.users;
  //console.log("post", post);
  const [listimages, setlistimages] = useState([]);
  const [imagestatus, setImagestatus] = useState(false);
  const [filecomp, setFilecomp] = useState([]); //bu mu, bu da sıkıstıktan sonraki format
  const [imgurl, setimgurl] = useState([]);
  const [postid, setpostid] = useState([]);

  //Images

  function ReadImage(e) {
    e.preventDefault();
    console.log("The link was clicked.");

    images.map((img, i) => {
      listimages.push(img.guid);
      dataURLtoBlob(img.guid, img.post_parent);
    });
    setImagestatus(!imagestatus);
  }

  async function ImageImport(e) {
    e.preventDefault();
    console.log("filecomp", filecomp);

    filecomp.map(async (img, i) => {
      try {
        await Firebase.UploadImageImport(img).then((result) => {
          imgurl.push(result);

          console.log("url2", result);
        });
      } catch (error) {
        //alert(error.message);
        //seterror(error.message);
        console.log("error", error);
      }
      //setImagestatus(!imagestatus);
    });
  }
  //Images End

  function GetPost(e) {
    e.preventDefault();
    console.log("GetPost.....");
    Firebase.GetImportImagesForAds().then((result) => {
      //    imgurl.push(result);
      setpostid(result);
      console.log("Post Id : ", result);
    });
  }

  function ReadImageForAds(e) {
    e.preventDefault();
    console.log("Read Image.....");
    postid.map((pst, i) => {
      Firebase.ReadImportImagesForAds(pst).then((result) => {
        if (result[0]) imgurl.push(result);
        //setpostid(result);
        console.log("Post Id : ", pst);
      });
    });
  }

  function UpdatePost(e) {
    e.preventDefault();
    console.log("UpdatePost.....");
    console.log("imgurl", imgurl);

    imgurl.map((img, i) => {
      // console.log("Links", img[0].images, img[1]);
      Firebase.UpdateImageForAds(img[0].images, img[1]);
    });
  }

  function ReadPost(e) {
    e.preventDefault();
    console.log("The link was clicked.");

    post.map((pst, i) => {
      const newads = {
        activ: true,
        category: pst.Category,
        createDate: pst.post_date,
        desc: pst.post_content,
        phone: pst.phone,
        place: pst.place,
        price: pst.price.replace(".", ""),
        title: pst.post_title,
        userdisplayname: pst.display_name,
        postId: pst.post_id,
        useremail: pst.user_email,
        userId: pst.post_author,
        images: [],
      };
      //post.push(pst.guid);
      //console.log("pst", newads);
      Firebase.AddAdsImport(newads, pst.post_id);
    });
  }

  function DeletePost(e) {
    e.preventDefault();
    Firebase.DeletePost();
  }

  function DeleteImageImport(e) {
    e.preventDefault();
    Firebase.DeleteImageImport();
  }

  async function dataURLtoBlob(dataURL, postId) {
    const proxyurl = "http://localhost:8080/";
    const response = await fetch(proxyurl + dataURL);
    const blob = await response.blob();
    var filename =
      new Date().getTime() +
      dataURL.split("/").pop().split("#")[0].split("?")[0];
    console.log("blob", filename);

    filecomp.push([blob, filename, postId]);
  }

  async function ReadUSer(e) {
    e.preventDefault();
    users.map(async (user, i) => {
      console.log(
        "User",
        user.display_name,
        user.user_email,
        user.user_pass,
        "",
        "Abidjan"
      );
      try {
        await Firebase.registerforImport(
          user.display_name,
          user.user_email,
          user.user_pass,
          "",
          "Abidjan"
        );
        //history.push("/");
      } catch (error) {
        //alert(error.message);
        //seterror(error.message);
        console.log("error", error);
      }
    });
  }

  function SendPasswordResetMail(e) {
    e.preventDefault();

    //Firebase.forgotPassword("kabakabaci@gmail.com");
    //return;
    users.map(async (user, i) => {
      console.log(
        "User",
        user.display_name,
        user.user_email,
        user.user_pass,
        "",
        "Abidjan"
      );
      try {
        //kullanmadan once burayı ac.
        await Firebase.forgotPassword(user.user_email);
        //history.push("/");
      } catch (error) {
        //alert(error.message);
        //seterror(error.message);
        console.log("error", error);
      }
    });
  }

  function AddSlug(e) {
    e.preventDefault();
    console.log("AddSlug.....");
    Firebase.ListAdsForSlug().then((result) => {
      //    imgurl.push(result);
      console.log("AddSlug : ", result);
    });
  }

  return (
    <div id="content">
      <div className="container-fluid">
        <div className="container">
          <button type="button" onClick={ReadImage} className="btn btn-primary">
            Read Image
          </button>
          &emsp;
          <button
            type="button"
            onClick={ImageImport}
            className="btn btn-primary"
          >
            Image Import
          </button>
          {imgurl.map((img, i) => {
            return (
              <img className="img-fluid img-thumbnail" src={img} alt={i} />
            );
          })}
          <p></p>
          <p>
            {" "}
            <button
              type="button"
              onClick={ReadPost}
              className="btn btn-primary"
            >
              Read Post
            </button>
            &emsp;
            <button
              type="button"
              //onClick={ImageImport}
              className="btn btn-primary"
            >
              --Post Import
            </button>
          </p>
          <p></p>
          <p>
            {" "}
            <button type="button" onClick={GetPost} className="btn btn-primary">
              Get Post For Image
            </button>
            &emsp; &emsp;
            <button
              type="button"
              onClick={ReadImageForAds}
              className="btn btn-primary"
            >
              Read Image
            </button>
            &emsp; &emsp;
            <button
              type="button"
              onClick={UpdatePost}
              className="btn btn-primary"
            >
              Update Post
            </button>
          </p>
          <p>
            {" "}
            <button
              type="button"
              onClick={DeletePost}
              className="btn btn-primary"
            >
              Delete Post
            </button>
            &emsp;
            <button
              type="button"
              onClick={DeleteImageImport}
              className="btn btn-primary"
            >
              Delete Image Import
            </button>
          </p>
          <p>
            {" "}
            <button
              type="button"
              onClick={ReadUSer}
              className="btn btn-primary"
            >
              Read USer
            </button>
            &emsp;
            <button
              type="button"
              onClick={SendPasswordResetMail}
              className="btn btn-primary"
            >
              Send Forgot Password Mail
            </button>
          </p>
          <p>
            {" "}
            <button type="button" onClick={AddSlug} className="btn btn-primary">
              Add Slug
            </button>
            &emsp;
          </p>
        </div>
      </div>
    </div>
  );
}
