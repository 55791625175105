import React, { useState, useEffect } from "react";
import SendMessage from "../message/sendmessage";
import Firebase from "../../firebase";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import { formatmoney, noimageurl, returndatetime } from "../../generalfunc";
import renderHTML from "react-render-html";
import { Helmet } from "react-helmet";

const AdsDetail = (props) => {
  const [isOpen, setisOpen] = useState();
  const [listAds, setlistAds] = useState();
  const [images, setImages] = useState();
  const [adsid, setAdsid] = useState();
  const [islogin, setIsLogin] = useState(false);

  useEffect(() => {
    console.log("formatted_date", returndatetime());
    setisOpen(false);
    if (props.match.params.id) {
      setAdsid(props.match.params.id);
      setIsLogin(Firebase.isLogin());
      Firebase.GetAdsDetail(props.match.params.id).then(
        (result) => {
          setlistAds(result);
        },
        (error) => {
          console.log(error);
        }
      );
    }
    /*
    if (props.match.params.id) {
      Firebase.UpdateAdsImagePath(props.match.params.id).then(
        (result) => {
          //setlistAds(result);
        },
        (error) => {
          console.log(error);
        }
      );
    }
    */
  }, []);

  useEffect(() => {
    if (listAds) {
      /*
      listAds.images.forEach((img, id) => {
        //  Firebase.GetImageUrl(img).then((result) => {
        imagearr.push({
          original: img,
          thumbnail: img,
        });
        //});
      });
*/
      if (listAds.images.length > 0)
        setImages(
          listAds.images.map((img) => ({
            original: img,
            thumbnail: img,
          }))
        );
    }
  }, [listAds]);

  const toggleModal = () => {
    setisOpen(!isOpen);
  };

  return (
    <div id="content">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{listAds ? listAds.title : ""}</title>
        <meta name="description" content={listAds ? listAds.title : ""} />
      </Helmet>
      <div className="container-fluid">
        <h2 className="text-dark size-lg pl-3 pt-3">
          {listAds ? listAds.title : ""}
        </h2>
        <hr />
        <div className="">
          <div className="row ">
            <div className="col-9">
              {images ? (
                <ImageGallery items={images} />
              ) : (
                <img src={noimageurl()} className="card-img-top" />
              )}
            </div>
            <div className="col-3">
              <div className="sellerinfo">
                <ul className="list-group">
                  <li className="list-group-item">
                    <br />
                    <i className="fas fa-tags fa-lg"></i>
                    <b>{listAds ? formatmoney(listAds.price) : ""}</b>
                  </li>
                  <li className="list-group-item">
                    <br />
                    <img src="../img/user1.png" alt="user" />
                    {listAds ? listAds.userdisplayname : ""}
                  </li>
                  <li className="list-group-item">
                    <br />
                    <img src="../img/user2.png" alt="user" />
                    {listAds ? listAds.place : ""}
                  </li>
                  <li className="list-group-item">
                    <br />
                    <img src="../img/user3.png" alt="user" />
                    {listAds ? listAds.phone : ""}
                  </li>
                  <li className="list-group-item">
                    <br />
                    <img src="../img/user4.png" alt="user" />
                    {islogin ? (
                      <button
                        type="button"
                        className="btn btn-link"
                        data-toggle="modal"
                        data-target="#exampleModal"
                        onClick={(e) => {
                          toggleModal();
                        }}
                      >
                        Message
                      </button>
                    ) : (
                      <span
                        class="d-inline-block"
                        tabindex="0"
                        data-bs-toggle="tooltip"
                        title="Vous devez être connecté"
                      >
                        &nbsp;Pas connecté
                      </span>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr />
          <div className="row justify-content-left pl-3 py-3">
            <h4>{listAds ? listAds.category : ""}</h4>
          </div>
          <div className="row justify-content-left pl-3 py-3">
            <h5>{listAds ? formatmoney(listAds.price) : ""}</h5>
          </div>
          <div className="row justify-content-left pl-3 py-3">
            <h6>{listAds ? renderHTML(listAds.desc) : ""}</h6>
          </div>
        </div>
      </div>
      <SendMessage
        show={isOpen}
        onClose={toggleModal}
        ads={listAds ? listAds : ""}
        adsid={adsid}
      >
        Here's some content for the modal
      </SendMessage>
    </div>
  );
};

export default AdsDetail;
