import * as firebase from "firebase/app";
import "firebase/storage";
import "firebase/firestore";
import "firebase/auth";
import urlSlug from "url-slug";

var firebaseConfig = {
  apiKey: "AIzaSyCj26No22WC1MAPxGkIDNfBpMc0AJn2EGY",
  authDomain: "kabakaba-v2.firebaseapp.com",
  databaseURL: "https://kabakaba-v2.firebaseio.com",
  projectId: "kabakaba-v2",
  storageBucket: "kabakaba-v2.appspot.com",
  messagingSenderId: "76699093960",
  appId: "1:76699093960:web:a5b9f837c4f0f5463fcefd",
  measurementId: "G-T9C87PQBWL",
};

class Firebase {
  constructor() {
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);

    this.projectStorage = firebase.storage();
    this.projectFirestore = firebase.firestore();
    this.projectAuth = firebase.auth();
    this.timestamp = firebase.firestore.FieldValue.serverTimestamp;
  }

  getImagePath() {
    return "gs://kabakaba-v2.appspot.com/images/";
  }

  async AddConversation(data) {
    var roomName =
      data.User1Id < data.User2Id
        ? data.User1Id + "_" + data.User2Id
        : data.User2Id + "_" + data.User1Id;

    console.log("roomName", data);
    //return;

    const convRef = await this.projectFirestore
      .collection("Conversation")
      .doc(roomName);

    const snapshot = await convRef.get();
    if (!snapshot.exists) {
      console.log("No such document!");
      const res = await convRef.set(data);
      return;
    } else {
      console.log("Var");
      const message = snapshot.data().message;
      message.push(data.message[0]);

      convRef
        .update({
          LastDate: Date.now(),
          message: message,
        })
        .then(function () {
          //console.log("Frank food updated");
        });
    }
  }

  async ListConversation2(data) {
    const convRef = this.projectFirestore.collection("Conversation");
    var result = [];
    var userid = this.projectAuth.currentUser.email;

    const snapshot = await convRef
      .where("users", "array-contains", userid)
      //.where("members.User2Id", "==", "cccc")
      .orderBy("LastDate", "desc")
      .limit(20)
      .get();
    //console.log("snapshot", snapshot);

    if (snapshot.empty) {
      console.log("No such document!");
    } else {
      snapshot.forEach((doc) => {
        //console.log("=>", doc.data());

        let currentID = doc.id;
        let appObj = { ...doc.data(), ["id"]: currentID };
        result.push(appObj);
      });
      return result;
    }
  }

  /*
  async AddConversation(data) {
    var roomName =
      data.User1Id < data.User2Id
        ? data.User1Id + "_" + data.User2Id
        : data.User2Id + "_" + data.User1Id;

    console.log(roomName);
    //return;

    const convRef = this.projectFirestore
      .collection("Conversation")
      .doc(roomName);

    const snapshot = await convRef.get();
    if (!snapshot.exists) {
      //console.log("No such document!");
      const res = convRef.set(data);
      return res.id;
    } else {
      //console.log("Document data:", snapshot.data());
      const message = snapshot.data().message;
      message.push(data.message[0]);

      convRef
        .update({
          message: message,
        })
        .then(function () {
          //console.log("Frank food updated");
        });
    }

    /*
    const fireSQL = new FireSQL(this.projectFirestore);

    const citiesPromise = fireSQL.query(`
  SELECT *
  FROM Conversation
  WHERE (User1Id = '${data.User1Id}'  AND User2Id = '${data.User2Id}' ) 
     OR (User1Id = '${data.User2Id}'  AND User2Id = '${data.User1Id}' )
`);

    citiesPromise.then((cities) => {
      console.log(cities);

      if (cities.length > 0) {
        console.log("Var");
        const message = cities[0].message;
        message.push(data.message[0]);

        this.projectFirestore
          .collection("Conversation")
          .doc(roomName)
          .update({
            message: message,
          })
          .then(function () {
            console.log("Frank food updated");
          });
      } else {
        console.log("Yok");
        const res = this.projectFirestore
          .collection("Conversation")
          .doc(roomName)
          .set(data);
        return res.id;
      }
      for (const city of cities) {
        console.log("cities", city.id);
      }
    });
    */
  //}

  async AddConversation2(data) {
    console.log(data.members);
    console.log(data.members.User1Id);
    const convRef = this.projectFirestore.collection("Conversation");
    var data = [];

    const snapshot = await convRef
      .where("users", "==", ["cccc", "aaaa"])
      //.where("members.User2Id", "==", "cccc")
      //.orderBy("createDate", "desc")e
      .limit(20)
      .get();
    console.log(snapshot);
    if (snapshot.docs.length > 0) console.log("Kayıt Var");
    snapshot.docs.map((doc) => data.push(doc.data()));
    console.log(data);
    var varmi = data.map((user) => user.users.includes("cccc"));
    console.log("var", varmi);
    /*
    var snapshotcont = snapshot.filter(function (msg) {
      msg.users.includes("cccc");
    });
    console.log("snapshotcont", snapshotcont);

    */
    console.log(snapshot.docs.map((doc) => doc.data()));

    //convRef.add(data);
    /*
    const res = this.projectFirestore
      .collection("Conversation")
      .add(data)
      .then(() => {
        console.log("Kayıt Ok");
      })
      .catch((error) => {
        console.log("Unable to get record. Pease try again", error);
      });
    return res.id;
    */
  }

  bulkUploadImage(images) {
    return new Promise((resolve, reject) => {
      var countimages = Object.keys(images).length;
      var adsimage = [];
      images.map((image) => {
        this.UploadImage(image).then((result) => {
          //          console.log("imageresult", result);
          adsimage.push(result);
          if (adsimage.length == countimages) resolve(adsimage);
        });
      });
    });
  }

  AddAdsData(data) {
    return new Promise((resolve, reject) => {
      const res = this.projectFirestore
        .collection("Cads")
        .add(data)
        .then((result) => {
          //      console.log("Adsresult", result.id);
          //return result.id;
          resolve(result.id);
        });
    });
    //    callback(res.id);
  }

  async AddAds(data, images) {
    console.log("images", images.length);
    if (images.length > 0) {
      this.bulkUploadImage(images).then((imgdata) => {
        //    console.log("bulkUpload", imgdata);
        this.AddAdsData(data).then((adsid) => {
          this.UpdateAdsImagePath(adsid);
        });
      });
    } else {
      this.AddAdsData(data).then((adsid) => {
        //this.UpdateAdsImagePath(adsid);
      });
    }
    /*
    images.map((image) => {
      //console.log(image);
      this.UploadImage(image);
    });

    const res = await this.projectFirestore.collection("Cads").add(data);
    await this.UpdateAdsImagePath(res.id);
    return res.id;
    */
  }

  async AddAdsImport(data, postId) {
    const res = await this.projectFirestore.collection("Cads").doc(postId);
    const snapshot = await res.get();
    if (!snapshot.exists) {
      //console.log("No such document!");
      const res2 = res.set(data);
    }
    console.log("res.id", postId);
    //return res.id;
  }

  async EditAds(data, images, adsid) {
    /*
    images.map((image) => {
      this.UploadImage(image);
    });
*/
    const cadsRef = this.projectFirestore.collection("Cads").doc(adsid);
    await cadsRef.update({
      activ: false,
      category: data.category,
      updateDate: data.createDate,
      title: data.title,
      price: data.price,
      desc: data.desc,
      phone: data.phone,
      place: data.place,
    });

    //await this.UpdateAdsImagePath(res.id);
    //return res.id;
  }

  async MakeActivAds(adsid) {
    if (adsid !== "") {
      const cadsRef = this.projectFirestore.collection("Cads").doc(adsid);
      await cadsRef.update({
        activ: true,
      });
    } else {
      //After
    }
  }

  async AddSlugAdsForOldData(adsid, title) {
    console.log("ads", adsid);

    // adsid = "4996";
    //After
    const cadsRef = this.projectFirestore.collection("Cads").doc(adsid);
    //return;

    await cadsRef.set(
      {
        slug: urlSlug(title),
      },
      { merge: true }
    );
  }

  async ListAdsForSlug() {
    const cadsRef = this.projectFirestore.collection("Cads");
    const result = [];
    const snapshot = await cadsRef
      //.where("postId", "==", "4996")
      .orderBy("createDate", "desc")
      //.startAt(1)
      //.limit(5)
      .get();

    if (snapshot.empty) {
      console.log("No such document!");
    } else {
      //return snapshot.docs.map((doc) => doc.data());
      snapshot.forEach((doc) => {
        //console.log(doc.id, "=>", doc.data());

        let currentID = doc.id;
        let title = doc.data().title;
        this.AddSlugAdsForOldData(currentID, title);
      });
      return result;
    }
  }

  async MakeActivFalseAds(adsid) {
    //console.log("adsid", adsid);
    //return;
    if (adsid !== "") {
      const cadsRef = this.projectFirestore.collection("Cads").doc(adsid);
      await cadsRef.update({
        activ: false,
      });
    } else {
      //After
    }
  }

  async GetAdsForSitemap() {
    const cadsRef = this.projectFirestore.collection("Cads");
    const result = [];
    const snapshot = await cadsRef
      .where("activ", "==", true)
      .orderBy("createDate", "desc")
      //.startAt(1)
      //.limit(150)
      .get();

    if (snapshot.empty) {
      console.log("No such document!");
    } else {
      //return snapshot.docs.map((doc) => doc.data());
      snapshot.forEach((doc) => {
        //console.log(doc.id, "=>", doc.data());

        let currentID = doc.id;
        let appObj = { ...doc.data(), ["id"]: currentID };
        result.push(appObj);
      });
      return result;
    }
  }

  async GetAdsForMainPage() {
    const cadsRef = this.projectFirestore.collection("Cads");
    const result = [];
    const snapshot = await cadsRef
      .where("activ", "==", true)
      .orderBy("createDate", "desc")
      //.startAt(1)
      .limit(150)
      .get();

    if (snapshot.empty) {
      console.log("No such document!");
    } else {
      //return snapshot.docs.map((doc) => doc.data());
      snapshot.forEach((doc) => {
        //console.log(doc.id, "=>", doc.data());

        let currentID = doc.id;
        let appObj = { ...doc.data(), ["id"]: currentID };
        result.push(appObj);
      });
      return result;
    }
  }

  async getUserDetailFromMail(email) {
    email = "yakarali@hotmail.com";
    const userRef = this.projectFirestore.collection("User");
    var result;
    const snapshot = await userRef.where("email", "==", email).get();

    if (snapshot.empty) {
      console.log("No such document!");
    } else {
      //console.log("snapshot", snapshot.data());
      return snapshot.docs.map((doc) => doc.data());
      //snapshot.forEach((doc) => {
      //console.log(doc.id, "=>", doc.data());

      //let currentID = doc.id;
      //let appObj = { ...doc.data(), ["id"]: currentID };
      //result.push(appObj);
      // console.log("doc.data()", doc.data().displayName);
      // result = doc.data().displayName;
      // });
      return result;
    }
  }

  async GetAdsWithCategory(category) {
    const cadsRef = this.projectFirestore.collection("Cads");
    const result = [];

    const snapshot = await cadsRef
      .where("category", "==", category)
      .orderBy("createDate", "desc")
      .limit(100)
      .get();

    if (snapshot.empty) {
      console.log("No such document!");
    } else {
      snapshot.forEach((doc) => {
        //console.log(doc.id, "=>", doc.data());

        let currentID = doc.id;
        let appObj = { ...doc.data(), ["id"]: currentID };
        result.push(appObj);
      });
      return result; //.docs.map((doc) => doc.data());
      //return snapshot.docs.map((doc) => doc.data());
    }
  }

  async GetAdsWithSearch(search) {
    const cadsRef = this.projectFirestore.collection("Cads");
    const result = [];
    console.log("search", search);
    const snapshot = await cadsRef
      //.where("desc", ">=", search)
      .where("desc", "<=", search + "\uf8ff")
      //.where("desc", ">=", search + "\uf8ff")
      .orderBy("desc")
      .orderBy("createDate", "desc")
      .limit(50)
      .get();

    if (snapshot.empty) {
      console.log("No such document!");
    } else {
      snapshot.forEach((doc) => {
        //console.log(doc.id, "=>", doc.data());

        let currentID = doc.id;
        let appObj = { ...doc.data(), ["id"]: currentID };
        result.push(appObj);
      });
      return result; //.docs.map((doc) => doc.data());
      //return snapshot.docs.map((doc) => doc.data());
    }
  }

  async GetAdsDetail(adsId) {
    //adsId = "Q7Ag3xa2U1bFtH57MLNkAHiW3T22";
    const cadsRef = this.projectFirestore.collection("Cads").doc(adsId);

    const snapshot = await cadsRef.get();
    //console.log("snapshot", snapshot.data());

    if (snapshot.empty) {
      console.log("No such document!");
    } else {
      return snapshot.data();
    }
  }

  async UpdateAdsImagePath(adsId) {
    const cadsRef = this.projectFirestore.collection("Cads").doc(adsId);

    const snapshot = await cadsRef.get();
    var imgpath = [];

    if (snapshot.empty) {
      console.log("No such document!");
    } else {
      //console.log("Update Path");

      //snapshot.data().images.forEach((img) => {
      for (var i = 0; i < snapshot.data().images.length; i++) {
        await this.GetImageUrl(snapshot.data().images[i]).then((result) => {
          imgpath.push(result);
        });
      }

      if (!imgpath.empty)
        await cadsRef
          .update({
            images: imgpath,
          })
          .then(function () {
            //console.log("Frank food updated");
          });
    }
  }

  async GetAdsForAdminUser() {
    const cadsRef = this.projectFirestore.collection("Cads");
    const result = [];

    const snapshot = await cadsRef
      .where("activ", "==", false)
      .orderBy("createDate", "desc")
      .get();

    if (snapshot.empty) {
      console.log("No such document!");
    } else {
      //const data = await snapshot.docs.map((doc) => doc.data());
      snapshot.forEach((doc) => {
        //console.log(doc.id, "=>", doc.data());
        let currentID = doc.id;
        let appObj = { ...doc.data(), ["id"]: currentID };
        result.push(appObj);
      });
      return result; //.docs.map((doc) => doc.data());
    }
  }

  async GetAdsWithUser(userId) {
    //userId = "Q7Ag3xa2U1bFtH57MLNkAHiW3T22";
    //userId = this.getTokenWithExpiry();
    var email = this.getTokenEmailExpiry();
    const cadsRef = this.projectFirestore.collection("Cads");
    const result = [];

    const snapshot = await cadsRef
      //.where("userId", "==", userId)
      .where("useremail", "==", email)
      .orderBy("createDate", "desc")
      .get();

    if (snapshot.empty) {
      console.log("No such document!");
    } else {
      //const data = await snapshot.docs.map((doc) => doc.data());
      snapshot.forEach((doc) => {
        //console.log(doc.id, "=>", doc.data());

        let currentID = doc.id;
        let appObj = { ...doc.data(), ["id"]: currentID };
        result.push(appObj);
      });
      return result; //.docs.map((doc) => doc.data());
    }
    //const data = snapshot.data();

    /*
    if (snapshot.empty) {
      console.log("No matching documents.");
      return;
    }
    return Promise.all(snapshot.docs.map((doc) => doc.data()));
    */
    /*
    snapshot.forEach((doc) => {
      console.log(doc.id, "=>", doc.data());
    });
    */
  }

  async GetImageUrl(filename) {
    //console.log(filename);
    const storageRef = this.projectStorage.ref(`images/${filename}`);
    const url = await storageRef.getDownloadURL();
    //console.log(url);
    return url;
  }

  async UploadImageImport(file) {
    const storageRef = await this.projectStorage.ref(`images/${file[1]}`);
    //const collectionRef = this.projectFirestore.collection("images");

    await storageRef.put(file[0]).on(
      "state_changed",
      (snap) => {
        let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
      },
      (err) => {
        console.log("err", err);
      },
      async () => {
        const url = await storageRef.getDownloadURL();
        console.log("url", url);
        /*
        const newImg = {
          postId: file[2],
          images: [
            {
              imagelink: url,
              image: file[1],
              createDate: Date.now(),
            },
          ],
        };
        */
        const newImg = {
          images: [url],
        };
        await setTimeout(() => {
          console.log("1 second finished!");
        }, 3000);
        await this.AddImportImage(newImg, file[2]);
        /*
        const res = await this.projectFirestore
          .collection("ImportImage")
          .doc(file[2])
          .set(newImg);
        console.log("res", res);
        //return url;
        */
      }
    );
  }

  async AddImportImage(newImg, postId) {
    const convRef = await this.projectFirestore
      .collection("ImportImage")
      .doc(postId);

    const snapshot = await convRef.get();
    if (!snapshot.exists) {
      //console.log("No such document!");
      const res = convRef.set(newImg);
      //return res.id;
    } else {
      console.log("Document data:", snapshot.data().images);
      const image = await snapshot.data().images;
      image.push(newImg.images[0]);
      console.log("Var", newImg.images[0]);

      console.log("image", image);
      await convRef
        .update({
          images: image,
        })
        .then(function () {
          //console.log("Frank food updated");
        });
    }
  }

  async GetImportImagesForAds() {
    const cadsRef = await this.projectFirestore.collection("Cads");
    const result = [];
    const snapshot = await cadsRef
      //.where("activ", "==", true)
      .orderBy("createDate", "desc")
      //.startAt(1)
      //.limit(100)
      .get();

    if (snapshot.empty) {
      console.log("No such document!");
    } else {
      //return snapshot.docs.map((doc) => doc.data());
      snapshot.forEach(async (doc) => {
        //console.log(doc.id, "=>", doc.data());

        let currentID = doc.id;
        //let appObj = { ...doc.data(), ["id"]: currentID };
        //result.push(appObj);
        if (doc.data().postId)
          //return doc.data().postId;
          result.push(doc.data().postId);
        //console.log(doc.data().postId);
        /*
        if (doc.data().postId) {
          const convRef = await this.projectFirestore
            .collection("ImportImage")
            .doc(doc.data().postId);
          const snapshotImg = await convRef.get();
          console.log("snapshotImg", snapshotImg.data());
          const image = await snapshotImg.data().images;
          //console.log("image", image);

          const cadsRefImg = await this.projectFirestore
            .collection("Cads")
            .doc(doc.id);

          await cadsRefImg
            .update({
              images: image,
            })
            .then(function () {
              console.log("updated");
            });
        }
        console.log("doc.data()", doc.data().postId);
        */
      });
      return result;
    }
  }

  async DeletePost() {
    const cadsRef = await this.projectFirestore.collection("Cads");
    const result = [];
    const snapshot = await cadsRef.get();

    if (snapshot.empty) {
      console.log("No such document!");
    } else {
      snapshot.forEach(async (doc) => {
        let currentID = doc.id;
        //if (doc.data().postId) result.push(doc.data().postId);

        const res = await this.projectFirestore
          .collection("Cads")
          .doc(doc.id)
          .delete();
        console.log("Deleted", doc.id);
      });
      return result;
    }
  }

  async DeleteImageImport() {
    const cadsRef = await this.projectFirestore.collection("ImportImage");
    const result = [];
    const snapshot = await cadsRef.get();

    if (snapshot.empty) {
      console.log("No such document!");
    } else {
      snapshot.forEach(async (doc) => {
        let currentID = doc.id;
        //if (doc.data().postId) result.push(doc.data().postId);

        const res = await this.projectFirestore
          .collection("ImportImage")
          .doc(doc.id)
          .delete();
        console.log("Deleted", doc.id);
      });
      return result;
    }
  }

  async ReadImportImagesForAds(postId) {
    console.log("PostId : ", postId);
    var result = [];

    const convRef = await this.projectFirestore
      .collection("ImportImage")
      .doc(postId);

    const snapshot = await convRef.get();

    if (snapshot.empty) {
      console.log("No such document!");
      return;
    } else {
      //return snapshot.docs.map((doc) => doc.data());
      //snapshot.forEach((doc) => {
      //console.log(doc.id, "=>", doc.data());

      // console.log("Image", snapshot.data());
      var image = await snapshot.data();
      await result.push(snapshot.data(), postId);
      /*
      const cadsRefImg = await this.projectFirestore
        .collection("Cads")
        .doc(postId);

      await cadsRefImg
        .update({
          images: image,
        })
        .then(function () {
          console.log("updated");
        });
      */
      /*
        let currentID = doc.id;
        let appObj = { ...doc.data(), ["id"]: currentID };
        result.push(appObj);
        */
      //});
      // console.log("Img", image);
      return result;
    }
  }

  async UpdateImageForAds(images, postId) {
    const cadsRefImg = await this.projectFirestore
      .collection("Cads")
      .doc(postId);

    const snapshot = await cadsRefImg.get();

    if (snapshot.empty) {
      console.log("No such document!");
      return;
    } else {
      await cadsRefImg
        .update({
          images: images,
        })
        .then(function () {
          console.log("updated", postId);
        });
    }
  }

  async UploadImage(file) {
    // var newfilename = new Date().getTime() + file.name;
    const storageRef = this.projectStorage.ref(`images/${file.name}`);
    const collectionRef = this.projectFirestore.collection("images");

    await storageRef.put(file).on(
      "state_changed",
      (snap) => {
        let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
        //setProgress(percentage);
      },
      (err) => {
        //setError(err);
        console.log("err", err);
      },
      async () => {
        const url = await storageRef.getDownloadURL();
        //console.log("url", url);
        //const createdAt = this.timestamp();

        //await collectionRef.add({ url, createdAt });
      }
    );

    //return await storageRef.getDownloadURL();
  }

  /*Auth Start*/

  setToken() {
    //console.log(this.projectAuth.currentUser);
    var expr = 1000 * 60 * 60 * 24; //2 saat
    var token = {
      uid: this.projectAuth.currentUser.uid,
      name: this.projectAuth.currentUser.displayName,
      phoneNumber: this.projectAuth.currentUser.phoneNumber,
      email: this.projectAuth.currentUser.email,
      expiry: new Date().getTime() + expr,
    };
    localStorage.setItem("userToken", JSON.stringify(token));
    return token;
  }
  /*
  getUserProfile() {
    var user = this.projectAuth.currentUser;
    var name, email, photoUrl, uid, emailVerified;
    console.log(user);
    if (user != null) {
      name = user.displayName;
      email = user.email;
      photoUrl = user.photoURL;
      emailVerified = user.emailVerified;
      uid = user.uid; // The user's ID, unique to the Firebase project. Do NOT use
      // this value to authenticate with your backend server, if
      // you have one. Use User.getToken() instead.
    }
  }
*/
  loginwithfacebook() {
    var provider = new firebase.auth.FacebookAuthProvider();
    firebase.auth().languageCode = "fr_FR";
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        // ...
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
      });
  }

  //bu artık sayfa icinden cagrılmıyor.Yedek olarak kalsın.
  loginwithgoogle() {
    var provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().languageCode = "fr_FR";
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        // ...
        console.log("google user", user);
        return this.setToken();
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
      });
  }

  async login(email, password) {
    await this.projectAuth.signInWithEmailAndPassword(email, password);
    await this.ImportUser(email);
    return this.setToken();
  }

  async forgotPassword(email) {
    var auth = this.projectAuth;
    console.log(email);
    await auth
      .sendPasswordResetEmail(email)
      .then(function () {
        console.log("OK");
        return "OK";
      })
      .catch(function (error) {
        // An error happened.
        console.log(error);
        return error;
      });
  }

  UpdateUserProfileWithId(userName, place, phone) {
    //uid = "JbkOqwURgWTAKp943N1ntMyl5BA2";
    var userId = this.projectAuth.currentUser.uid;
    const cadsRef = this.projectFirestore.collection("User").doc(userId);
    cadsRef
      .update({
        //image: "update2",
        displayName: userName,
        place: place,
        phone: phone,
      })
      .then(function () {
        //console.log("Frank food updated");
      });
  }

  logout() {
    var key = "userToken";
    localStorage.removeItem(key);
    return this.projectAuth.signOut();
  }

  getTokenEmailExpiry(key) {
    key = "userToken";
    const itemStr = localStorage.getItem(key);

    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);

    const now = new Date();

    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    // console.log(item.email);

    return item.email;
  }

  getTokenWithExpiry(key) {
    key = "userToken";
    const itemStr = localStorage.getItem(key);

    // if the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);

    const now = new Date();

    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key);
      return null;
    }

    return item.uid;
  }

  async ListConversation(data) {
    const convRef = this.projectFirestore.collection("Conversation");
    var result = [];
    //var userid = this.getTokenWithExpiry();
    var userid = this.getTokenEmailExpiry();
    console.log("userid", userid);

    const snapshot = await convRef
      .where("users", "array-contains", userid)
      //.where("members.User2Id", "==", "cccc")
      .orderBy("LastDate", "desc")
      .limit(20)
      .get();

    if (snapshot.empty) {
      console.log("No such document!");
    } else {
      snapshot.forEach((doc) => {
        let currentID = doc.id;
        let appObj = { ...doc.data(), ["id"]: currentID };
        result.push(appObj);
      });
      return result;
    }
  }

  async register(name, email, password, phone, place) {
    await this.projectAuth.createUserWithEmailAndPassword(email, password);

    const newUser = {
      uid: this.projectAuth.currentUser.uid,
      displayName: name,
      email: email,
      phone: phone,
      image:
        "https://firebasestorage.googleapis.com/v0/b/kabakaba-v2.appspot.com/o/imgforsite%2Fnoprofile.png?alt=media&token=b12955a8-4552-49bf-93be-572d2536542e",
      place: place,
      createDate: Date.now(),
    };

    await this.projectAuth.currentUser
      .updateProfile({
        displayName: name,
      })
      .then(
        function () {
          // console.log("Profile OK");
        },
        function (error) {
          // An error happened.
          console.log("Eror", error);
        }
      );

    const res = await this.projectFirestore
      .collection("User")
      .doc(this.projectAuth.currentUser.uid)
      .set(newUser);

    console.log("Profile OK");
    return await this.setToken();
  }

  registerforSocial(displayName, email, image, uid) {
    const newUser = {
      uid: uid, //this.projectAuth.currentUser.uid,
      displayName: displayName,
      email: email,
      //phone: phone,
      image: image,
      //"https://firebasestorage.googleapis.com/v0/b/kabakaba-v2.appspot.com/o/imgforsite%2Fnoprofile.png?alt=media&token=b12955a8-4552-49bf-93be-572d2536542e",
      //place: place,
      createDate: Date.now(),
    };
    const res = this.projectFirestore
      .collection("User")
      .doc(this.projectAuth.currentUser.uid)
      .set(newUser);

    console.log("Profile OK");
    return this.setToken();
  }

  async registerforImport(name, email, password, phone, place) {
    await this.projectAuth.createUserWithEmailAndPassword(email, password);

    await this.projectAuth.currentUser
      .updateProfile({
        displayName: name,
      })
      .then(
        async function () {
          //await this.ImportUser(email);
          var name = this.projectAuth.currentUser.displayName;
          var userId = this.projectAuth.currentUser.uid;
          console.log("Geldi", name, userId);

          const newUser = {
            uid: userId,
            displayName: name,
            email: email,
            phone: "",
            image:
              "https://firebasestorage.googleapis.com/v0/b/kabakaba-v2.appspot.com/o/imgforsite%2Fnoprofile.png?alt=media&token=b12955a8-4552-49bf-93be-572d2536542e",
            place: "Abidjan",
            createDate: Date.now(),
          };

          const userRef = await this.projectFirestore
            .collection("User")
            .doc(userId);

          const snapshot = await userRef.get();

          if (!snapshot.exists) {
            //console.log("KAyıt Yok.");
            const res = await this.projectFirestore
              .collection("User")
              .doc(userId)
              .set(newUser);
            //console.log("res", res);
          } else {
            //console.log("KAyıt VAR.", snapshot);
            return;
          }
          console.log("Profile OK");
        },
        function (error) {
          // An error happened.
          console.log("Eror", error);
        }
      );

    //return await this.setToken();
  }

  async ImportUser(email) {
    var name = this.projectAuth.currentUser.displayName;
    var userId = this.projectAuth.currentUser.uid;
    console.log("Geldi", name, userId);

    const newUser = {
      uid: userId,
      displayName: name,
      email: email,
      phone: "",
      image:
        "https://firebasestorage.googleapis.com/v0/b/kabakaba-v2.appspot.com/o/imgforsite%2Fnoprofile.png?alt=media&token=b12955a8-4552-49bf-93be-572d2536542e",
      place: "Abidjan",
      createDate: Date.now(),
    };

    const userRef = await this.projectFirestore.collection("User").doc(userId);

    const snapshot = await userRef.get();

    if (!snapshot.exists) {
      //console.log("KAyıt Yok.");
      const res = await this.projectFirestore
        .collection("User")
        .doc(userId)
        .set(newUser);
      //console.log("res", res);
    } else {
      //console.log("KAyıt VAR.", snapshot);
      return;
    }
  }

  getCurrentUsername() {
    return this.projectAuth.currentUser && this.auth.currentUser.displayName;
  }

  getUsernameWithId(uid) {
    if (uid !== "") {
      var key = "userToken";
      const itemStr = localStorage.getItem(key);

      // if the item doesn't exist, return null
      if (!itemStr) {
        return null;
      }
      const item = JSON.parse(itemStr);

      return item.name;
    }
  }

  isLogin() {
    const user = this.getTokenWithExpiry();
    if (user) {
      return true;
    } else {
      return false;
    }
  }

  changePassword(newPassword) {
    var user = this.projectAuth.currentUser;
    //var newPassword = getASecureRandomPassword();

    user
      .updatePassword(newPassword)
      .then(function () {
        // Update successful.
        console.log("Update successful.");
      })
      .catch(function (error) {
        // An error happened.
        console.log("updatePassword error.", error);
      });
  }

  async getUserProfile(userId) {
    //console.log("userId", this.getTokenWithExpiry());
    if (userId) {
      return await this.GetUserWithId(userId);
    } else {
      return await this.GetUserWithId(this.getTokenWithExpiry());
    }
  }

  async GetUserWithId(uid) {
    //email = "aY6t7h5fU2RZ1y1Wxo8EZkx3cI72";
    const cadsRef = await this.projectFirestore.collection("User");
    //console.log("uid", uid);
    const snapshot = await cadsRef
      .where("uid", "==", uid)
      //.orderBy("createDate", "desc")
      .get();

    //const snapshot = await cadsRef.get();
    //console.log("snapshot", snapshot.data());

    if (snapshot.empty) {
      console.log("No such document!");
    } else {
      return snapshot.docs.map((doc) => doc.data());
    }
  }

  getUserPhoneWithId(uid) {
    if (uid !== "") {
      var key = "userToken";
      const itemStr = localStorage.getItem(key);

      // if the item doesn't exist, return null
      if (!itemStr) {
        return null;
      }
      const item = JSON.parse(itemStr);

      return item.phoneNumber;
    }
  }

  getUserId() {
    var user = this.projectAuth.currentUser;

    console.log(this.projectAuth);
    if (user) {
      return user.uid;
    } else {
      return 0;
    }
  }
  /*Auth End*/
}

export default new Firebase();
