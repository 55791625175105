export function formatmoney(money) {
  const formatter = new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "XOF",
    //minimumFractionDigits: 0,
  });
  return formatter.format(money);
}

export function noimageurl() {
  return "https://firebasestorage.googleapis.com/v0/b/kabakaba-v2.appspot.com/o/imgforsite%2Fnophoto.png?alt=media&token=38d46325-8ce4-45e2-ac84-a354372078a6";
}

function appendLeadingZeroes(n) {
  if (n <= 9) {
    return "0" + n;
  }
  return n;
}

export function returndatetime() {
  let current_datetime = new Date();
  console.log(current_datetime.toString());
  let formatted_date =
    current_datetime.getFullYear() +
    "-" +
    appendLeadingZeroes(current_datetime.getMonth() + 1) +
    "-" +
    appendLeadingZeroes(current_datetime.getDate()) +
    " " +
    appendLeadingZeroes(current_datetime.getHours()) +
    ":" +
    appendLeadingZeroes(current_datetime.getMinutes()) +
    ":" +
    appendLeadingZeroes(current_datetime.getSeconds());

  return formatted_date;
}
