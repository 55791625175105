import React, { useState, useEffect } from "react";
import Firebase from "../../firebase";
import Adsline from "../general/adsline";

const ListUserAds = () => {
  const [listAds, setlistAds] = useState();

  useEffect(() => {
    console.log("error", "");
    Firebase.GetAdsWithUser().then(
      (result) => {
        setlistAds(result);
        //console.log("result", result);
      },
      (error) => {
        console.log("error", error);
      }
    );
  }, []);

  function refreshPage() {
    window.location.reload();
  }

  const MakeDelete = (e, id) => {
    e.preventDefault();
    if (window.confirm("Are you sure you wish to delete this item?")) {
      Firebase.MakeActivFalseAds(id).then(() => {
        refreshPage();
      });
    }
  };

  return (
    <div id="content">
      <div className="container-fluid">
        {listAds
          ? listAds.map((ads, i) => {
              return (
                <Adsline
                  key={i}
                  ads={ads}
                  userbutton={true}
                  MakeDelete={MakeDelete}
                />
              );
            })
          : ""}
      </div>
    </div>
  );
};

export default ListUserAds;
