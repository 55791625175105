import React, { useState, useEffect } from "react";
import Firebase from "../../firebase";
import Adsline from "../general/adsline";

const ListSearchAds = (props) => {
  const [listAds, setlistAds] = useState();
  //const [image, setimage] = useState();

  useEffect(() => {
    console.log("props.match.params.search", props.match.params);
    if (props.match.params.search) {
      Firebase.GetAdsWithSearch(props.match.params.search).then(
        (result) => {
          setlistAds(result);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, []);

  return (
    <div id="content">
      <div className="container-fluid">
        {listAds
          ? listAds.map((ads, i) => {
              return <Adsline key={i} ads={ads} />;
            })
          : ""}
      </div>
    </div>
  );
};

export default ListSearchAds;
