import React, { useState, useEffect } from "react";
import Firebase from "../../firebase";
//const { createWriteStream } = require("fs");
//const { SitemapStream } = require("sitemap");

export default function Sitemap() {
  const [listAds, setlistAds] = useState();
  const [result, setResult] = useState();
  const EXTERNAL_DATA_URL = "https://kabakabaci.com/adsdetail";

  useEffect(() => {
    //    Firebase.register("name", "haluk@gmail.com", "123456", "phone", "place");
    //  return;
    Firebase.GetAdsForSitemap().then(
      (result) => {
        setlistAds(result);
        console.log("result", result);
      },
      (error) => {}
    );
  }, []);

  const createSitemap = (posts) => `<?xml version="1.0" encoding="UTF-8"?>
    <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
        ${posts
          .map(({ id, createDate, slug }) => {
            return `    <url>
                        <loc>${`${EXTERNAL_DATA_URL}/${slug}/${id}`}</loc>
                        <lastmod>${`${createDate.substring(0, 10)}`}</lastmod>
                        <changefreq>monthly</changefreq>
                        <priority>0.8</priority>
                    </url>
                `;
          })
          .join("")}
    </urlset>
    `;

  function getInitialProps() {
    //const request = await fetch(EXTERNAL_DATA_URL);
    //const posts = await request.json();
    //var res;
    //res.setHeader("Content-Type", "text/xml");
    //res.write(createSitemap(listAds));
    //res.end();

    setResult(createSitemap(listAds));
  }

  return (
    <div>
      <button onClick={getInitialProps}>Logout</button>
      <label>
        Essay:
        <textarea value={result} />
      </label>
    </div>
  );
}
