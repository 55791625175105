import React, { useState, useEffect } from "react";
import { formatmoney, noimageurl } from "../../generalfunc";

function Adsline(props) {
  const { title, createDate, place, category, price, id, images, activ, slug } =
    props.ads;
  //const [image, setimage] = useState();
  // const image;//props.image;
  /*
  useEffect(() => {
    Firebase.GetImageUrl(images[0]).then((result) => {
      setimage(result);
    });
  
  }, []);
  */
  /*
  function MakeActive(id) {
    console.log("Geldi", id);
    Firebase.MakeActivAds(id);
  }
*/
  return (
    <>
      <div className="card cardlist divbord">
        <div className="row no-gutters">
          <div className="col-md-2">
            <img
              className="my-2 listimg"
              src={images[0] ? images[0] : noimageurl()}
              alt="..."
            />
          </div>
          <div className="col-md-10">
            <div className="card-body my-4">
              <h5 className="card-title">{title}</h5>
              <p className="card-text"></p>
              <ul>
                <li>
                  <i className="fa fa-clock iconcolor" aria-hidden="true" />
                  {new Date(createDate).toISOString().split("T")[0]}
                </li>
                <li>
                  <i
                    className="fa fa-map-marker iconcolor"
                    aria-hidden="true"
                  />
                  {place}
                </li>
                <li>
                  <i className="fa fa-tag iconcolor" aria-hidden="true" />
                  {category}
                </li>
              </ul>
              <p />
              {props.adminbutton ? (
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={(e) => props.MakeActive(id)}
                >
                  Make Active
                </button>
              ) : (
                ""
              )}
              <p className="price">{formatmoney(price)}</p>
              {props.userbutton ? (
                <>
                  {activ ? (
                    <>
                      <span className="badge rounded-pill bg-success text-dark">
                        Actif
                      </span>
                      &nbsp;&nbsp;
                      <button
                        type="button"
                        className="btn btn-dark btn-sm"
                        onClick={(e) => props.MakeDelete(e, id)}
                      >
                        Effacer
                      </button>
                    </>
                  ) : (
                    <span className="badge rounded-pill bg-warning text-dark">
                      En Attente
                    </span>
                  )}
                </>
              ) : (
                ""
              )}
              <a
                href={"/adsdetail/" + slug + "/" + id}
                role="button"
                className="btn btnlist form-control"
              >
                Détails
              </a>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </>
  );
}

export default Adsline;
