import React from "react";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-6 col-12">
            <div className="footer-box">
              <h6 className="text-light size-lg">
                Kabakaba, la référence des meilleurs petites annonces à Abidjan
                et partout en Côte d'Ivoire
              </h6>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/kabakaba-v2.appspot.com/o/imgforsite%2Fkabakabalogo.png?alt=media&token=c3ab095a-c2a0-4432-827f-71422c929f88"
                alt=""
                className="logo"
              />
              <hr />
              <ul className="folow-us">
                <li>
                  <a href="#">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/kabakaba-v2.appspot.com/o/imgforsite%2Fgoogleplay.jpg?alt=media&token=fae0a798-24e4-4f5e-aba3-1c051767a6a7"
                      alt="follow"
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/kabakaba-v2.appspot.com/o/imgforsite%2Fappstore.jpg?alt=media&token=e65316ab-e0a6-4756-963a-0edc934bd9bb"
                      alt="follow"
                    />
                  </a>
                </li>
              </ul>
              <ul className="social-link">
                <li className="fa-classipost">
                  <a href="#">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/kabakaba-v2.appspot.com/o/imgforsite%2Ffacebook.jpg?alt=media&token=6bf2aad6-75dc-43b7-8183-4e81b3c93e51"
                      alt="social"
                    />
                  </a>
                </li>

                <li className="tw-classipost">
                  <a href="#">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/kabakaba-v2.appspot.com/o/imgforsite%2Ftwitter.jpg?alt=media&token=e1078c94-c16e-490f-a19b-4358645da1d2"
                      alt="social"
                    />
                  </a>
                </li>
                <li className="tw-classipost">
                  <a href="https://pluscrea.net/" target="blank">
                    <h6 className="text-light size-lg">
                      Design and Develop By <span>PlusCrea.net</span>
                    </h6>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-12">
            <div className="footer-box">
              <h3 className="text-light size-lg">Liens rapides</h3>
              <hr />
              <ul className="useful-link">
                <li>
                  <a href="/login">Login</a>
                </li>
                <li>
                  <a href="/register">Inscription</a>
                </li>
                <li>
                  <a href="/Addads">
                    Postez vos petites Annonces à Abidjan et en Cote d’Ivoire
                  </a>
                </li>
                <li>
                  <a href="/search">
                    Recherche Petites Annonces en Cote d’ivoire
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
