import React, { useState, useEffect } from "react";
import Firebase from "../../firebase";
import parameter from "../../data/parameter.json";
import UserComboBox from "../general/combobox";

function Profile() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [place, setPlace] = useState("");
  const [error, seterror] = useState("");
  const [success, setsuccess] = useState("");
  const [profile, setProfile] = useState("");
  const Place = parameter.Place;

  useEffect(() => {
    getUserProfile();
  }, []);

  const getUserProfile = () => {
    Firebase.getUserProfile().then(
      (result) => {
        setProfile(result[0]);
        console.log("result", result);
        setName(result[0].displayName);
        setEmail(result[0].email);
        setPlace(result[0].place);
        setPhone(result[0].phone);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  function register() {
    if (phone === "" || place === "" || name === "") {
      seterror("Remplissez tous les champs");
      return;
    }
    try {
      Firebase.UpdateUserProfileWithId(name, place, phone);
      //history.push("/myprofile");
      setsuccess("compte mis à jour....");
    } catch (error) {
      seterror(error.message);
    }
  }
  return (
    <div id="content">
      <div className="container-fluid">
        <br />
        <div className="loginsignup p-3">
          <h2 className="text-center pb-1">Mon Compte</h2>
          <div className="loginbox-social"></div>
          <form onSubmit={(e) => e.preventDefault()}>
            {error !== "" ? (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            ) : (
              ""
            )}
            {success !== "" ? (
              <div className="alert alert-success" role="alert">
                {success}
              </div>
            ) : (
              ""
            )}
            <div className="form-group">
              <label>Nom</label>
              <input
                type="text"
                className="form-control"
                name="name"
                autoFocus
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>E-mail</label>
              <input
                type="email"
                className="form-control"
                name="email"
                defaultValue={email}
                //value={email}
                readOnly
                //onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label>Téléphone de contact</label>
              <input
                type="text"
                className="form-control"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="form-group">
              <UserComboBox
                title="Ville"
                datas={Place}
                name="place"
                value={place}
                handleChange={(e) => setPlace(e.target.value)}
              />
            </div>
            <button type="submit" className="btn btn-danger" onClick={register}>
              S'Inscrire
            </button>
          </form>
        </div>
        <br />
      </div>
    </div>
  );
}

export default Profile;
