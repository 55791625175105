import React, { useState } from "react";
import Firebase from "../../firebase";

const ForgotPass = () => {
  const [email, setEmail] = useState("");
  const [error, seterror] = useState("");
  const [result, setResult] = useState(false);

  /*
  async function logout() {
    await Firebase.logout();
  }
*/
  async function forgot() {
    if (email === "") {
      seterror("Problem Email");
      return;
    }

    Firebase.forgotPassword(email).then(
      (result) => {
        seterror("OK");
        setResult(true);
      },
      (error) => {
        seterror(error);
      }
    );
  }

  return (
    <div id="content">
      <div className="container-fluid">
        <br />
        <div className="loginsignup p-3">
          <h2 className="text-center pb-1">Mot de passe oublié?</h2>
          {result == false ? (
            <form onSubmit={(e) => e.preventDefault()}>
              {error !== "" ? (
                <div class="alert alert-danger" role="alert">
                  {error}
                </div>
              ) : (
                ""
              )}
              <div className="form-group">
                <label>E-mail</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  autoFocus
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <button type="submit" className="btn btn-danger" onClick={forgot}>
                Réinitialiser mon compte
              </button>
            </form>
          ) : (
            <h2 className="text-center pb-1">{error}</h2>
          )}
        </div>
        <br />
      </div>
    </div>
  );
};

export default ForgotPass;
