import React, { useState, useEffect } from "react";
import parameter from "../../data/parameter.json";
import UserComboBox from "../general/combobox";
import Firebase from "../../firebase";
import Compressor from "compressorjs";
import { useHistory } from "react-router-dom";
import { returndatetime } from "../../generalfunc";
import urlSlug from "url-slug";

const Addads = () => {
  const [category, setCategory] = useState("");
  const [title, setTitle] = useState("");
  const [phone, setPhone] = useState("");
  const [price, setPrice] = useState(0);
  const [images, setimages] = useState([]); //bu ekranda gosterim icin
  //const [filearr, setFilearr] = useState([]); //bu da upload formatı icin
  const [filecomp, setFilecomp] = useState([]); //bu mu, bu da sıkıstıktan sonraki format
  const [imagestatus, setImagestatus] = useState(false);
  const [desc, setDesc] = useState("");
  const [place, setPlace] = useState("");
  const Place = parameter.Place;
  const Category = parameter.Category;
  const [error, seterror] = useState("");
  const [profile, setProfile] = useState("");
  let fileObj = [];
  let history = useHistory();

  const [image, setImage] = useState(null);
  //const [url, setUrl] = useState("");

  useEffect(() => {
    if (!Firebase.isLogin()) {
      alert("Vous devez être connecté");
      history.push("/login/");
      return;
    } else {
      getUserProfile();
    }
  }, []);

  const getUserProfile = () => {
    Firebase.getUserProfile().then(
      (result) => {
        setProfile(result[0]);
        console.log("result", profile);
        setPlace(result[0].place);
        setPhone(result[0].phone);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const fileChangedHandler = (e) => {
    fileObj.push(e.target.files);
    for (let i = 0; i < fileObj[0].length; i++) {
      images.push(URL.createObjectURL(fileObj[0][i]));
    }
    fileObj.length = 0;

    for (let i = 0; i < e.target.files.length; i++) {
      //filearr.push(e.target.files[i]);
      compressImage(e.target.files[i]);
    }
    //console.log(images);
    setImagestatus(!imagestatus);
  };

  const onDeleteClick = (e, index) => {
    e.preventDefault();
    images.splice(index, 1);
    //filearr.splice(index, 1);
    filecomp.splice(index, 1);
    setImagestatus(!imagestatus);
  };

  async function compressImage(filenew) {
    //filearr.map((filenew, i) => {
    new Compressor(filenew, {
      quality: 0.8,
      maxWidth: 500,
      maxHeight: 500,
      success(result) {
        var file = new File([result], new Date().getTime() + result.name);
        //setFilecomp([...filecomp, file]);
        filecomp.push(file);
      },
      error(err) {
        console.log(err.message);
      },
    });
    // });
  }

  async function onRegister(e) {
    e.preventDefault();
    /*
    if (
      category === "" ||
      title === "" ||
      price === 0 ||
      images === "" ||
      desc === "" ||
      place === ""
    ) {
      seterror("Tum alanları doldurun");
      return;
    }
*/

    console.log(filecomp);
    //Firebase.UploadImage(filecomp);

    /*
      Firebase.useStorage(images).then((url) => {
      console.log("Url", url);
    });
*/

    filecomp.map((filename, i) => {
      images[i] = filename.name;
    });

    const newAds = {
      category: category,
      title: title,
      price: price,
      images: images,
      desc: desc,
      phone: phone,
      place: place,
      activ: false,
      slug: urlSlug(title),
      userId: Firebase.getTokenWithExpiry(),
      userdisplayname: Firebase.getUsernameWithId(
        Firebase.getTokenWithExpiry()
      ),
      useremail: Firebase.getTokenEmailExpiry(),
      createDate: returndatetime(),
    };
    //console.log("newAds", newAds);
    Firebase.AddAds(newAds, filecomp).then(() => {
      history.push("/listuserads/");
    });
  }

  return (
    <div id="content">
      <div className="container-fluid">
        <br />
        <div className="loginsignup p-3">
          <h2 className="text-center pb-1">POSTER ANNONCE</h2>
          <h6 className="text-center">
            Postez vos petites Annonces à Abidjan et en Cote d’Ivoire
          </h6>
          <form onSubmit={onRegister}>
            {error !== "" ? (
              <div class="alert alert-danger" role="alert">
                {error}
              </div>
            ) : (
              ""
            )}
            <div className="form-group">
              <UserComboBox
                title="Catégorie"
                datas={Category}
                name="category"
                value={category}
                handleChange={(e) => setCategory(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Titre</label>
              <input
                type="text"
                className="form-control"
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Prix</label>
              <input
                type="number"
                className="form-control"
                name="price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>
            <label>Photo</label> <br />
            <div className="input-group mb-3">
              <div className="custom-file">
                <input
                  type="file"
                  className="custom-file-input"
                  id="inputGroupFile02"
                  onChange={fileChangedHandler}
                  multiple
                />
                <label
                  className="custom-file-label"
                  htmlFor="inputGroupFile02"
                  aria-describedby="inputGroupFileAddon02"
                >
                  Choose file
                </label>
              </div>
            </div>
            <div className="row text-center text-lg-left">
              {images.map((url, i) => {
                return (
                  <div className="col-lg-3 col-md-4 col-6" key={i}>
                    <a
                      href="#"
                      className="d-block mb-4 h-100"
                      onClick={(e) => {
                        onDeleteClick(e, i);
                      }}
                    >
                      <img
                        className="img-fluid img-thumbnail"
                        src={url}
                        alt={i}
                      />
                    </a>
                  </div>
                );
              })}
            </div>
            <div className="form-group">
              <label htmlFor="exampleFormControlTextarea1">
                Description de l'annonce
              </label>
              <textarea
                className="form-control"
                name="desc"
                value={desc}
                rows={5}
                onChange={(e) => setDesc(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Numéro de portable</label>
              <input
                type="text"
                className="form-control"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="form-group">
              <UserComboBox
                title="Ville"
                datas={Place}
                name="place"
                value={place}
                handleChange={(e) => setPlace(e.target.value)}
              />
            </div>
            <button type="submit" className="btn btn-danger">
              Envoyer
            </button>
          </form>
        </div>
        <br />
      </div>
    </div>
  );
};

export default Addads;
