import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Firebase from "../../firebase";

const Header = () => {
  const [search, setSearch] = useState("");
  let history = useHistory();
  const [islogin, setIslogin] = useState(false);
  //const [url, setUrl] = useState("");

  useEffect(() => {
    setIslogin(!Firebase.isLogin());
    //console.log("islogin", islogin);
  }, []);

  const something = (event) => {
    if (event.keyCode === 13) {
      console.log("enter");
      history.push(`/listsearchads/${search}`);
    }
  };

  return (
    <div className="header">
      <nav className="navbar">
        <a href="/" className="navbar-brand">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/kabakaba-v2.appspot.com/o/imgforsite%2Fkabakabalogo.png?alt=media&token=c3ab095a-c2a0-4432-827f-71422c929f88"
            alt=""
            className="logo"
          />
        </a>
        <div className="search">
          <div className="input-group">
            <input
              type="text"
              className="form-control form-control-lg form-control-warning"
              placeholder="Que recherchez-vous?"
              name="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={(e) => something(e)}
            />
            <div className="input-group-append">
              <a
                href={`/listsearchads/${search}`}
                role="button"
                className="btn btn-outline-danger btn-lg"
              >
                <i className="fas fa-search" /> Rechercher
              </a>
            </div>
          </div>
        </div>
        {islogin ? (
          <div className="login">
            <a href="/login">Login</a> | <a href="/register"> Inscription </a>
          </div>
        ) : (
          <div className="btn-group">
            <button
              type="button"
              className="btn btn btn-outline-info dropdown-toggle dropdown-toggle-split"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fas fa-user"></i>
            </button>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" href="/listuserads">
                  Mes annonces
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/mymessage">
                  Messages
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/myprofile">
                  Mon compte
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/changepassword">
                  Changer le Passe
                </a>
              </li>

              <li>
                <a className="dropdown-item" href="/logout">
                  Déconnexion
                </a>
              </li>
            </ul>
          </div>
        )}
        <div className="adsbutton">
          <a type="button" href="/Addads" className="btn btn-danger ml-2">
            + Poster Annonce
          </a>
        </div>
      </nav>
    </div>
  );
};

export default Header;
