//import { messaging } from "firebase";
import React, { useEffect, useState, useRef } from "react";
import Firebase from "../../firebase";
import MessageBuble from "./messagebuble";

export default function MyMessage(props) {
  const [listMsg, setListMsg] = useState();
  const [userId, setUserId] = useState();
  const [message, setMessage] = useState();
  const [msgout, setMsgout] = useState();
  //const [msgid, setMsgid] = useState(0);
  const [messagestatus, setMessageStatus] = useState(false);
  var msgid = 0;

  //useEffect(scrollToBottom, [message]);

  useEffect(() => {
    //messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    if (props.match.params.id) {
      // setMsgid(props.match.params.id);
      msgid = props.match.params.id;
    }
    ListConversation();
  }, []);

  function ListConversation() {
    Firebase.ListConversation("").then(
      (result) => {
        setListMsg(result);

        setUserId(Firebase.getTokenEmailExpiry());
        //setMessage(sortProperties(result[0].message, "createDate", true, true));
        setMessage(result[msgid].message);
        console.log("MSg", result[msgid]);
        //console.log("msgid", msgid);
      },
      (error) => {
        console.log("error", error);
      }
    );
  }

  /*
  function getOtherUser(user1, user2) {
    var defaultUser = Firebase.getTokenEmailExpiry();
    if (user1 == defaultUser) return user1;
    else return user2;
  }
*/
  const something = (event) => {
    if (event.keyCode === 13) {
      console.log("enter");
      sendMessage(event);
      //history.push(`/listsearchads/${search}`);
    }
  };

  function sendMessage(e) {
    //console.log("listMsg", listMsg[msgid]);

    e.preventDefault();
    console.log("sendmessage", msgout);

    if (msgout !== "") {
      const newConversation = {
        User1Id: listMsg[msgid].User1Id,
        User1Name: listMsg[msgid].User1Name,
        User2Id: listMsg[msgid].User2Id,
        User2Name: listMsg[msgid].User2Name,
        LastDate: Date.now(),
        users: [listMsg[msgid].User1Id, listMsg[msgid].User2Id],
        message: [
          {
            author: Firebase.getTokenEmailExpiry(),
            body: msgout,
            createDate: Date.now(),
            adsid: "",
            title: "",
          },
        ],
      };

      Firebase.AddConversation(newConversation);
      message.push({ author: Firebase.getTokenEmailExpiry(), body: msgout });
    }

    setMsgout("");
    setMessageStatus(!messagestatus);
  }
  /*
  function sortProperties(obj, sortedBy, isNumericSort, reverse) {
    sortedBy = sortedBy || 1; // by default first key
    isNumericSort = isNumericSort || false; // by default text sort
    reverse = reverse || false; // by default no reverse

    var reversed = reverse ? -1 : 1;

    var sortable = [];
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        sortable.push([key, obj[key]]);
      }
    }
    if (isNumericSort)
      sortable.sort(function (a, b) {
        return reversed * (a[1][sortedBy] - b[1][sortedBy]);
      });
    else
      sortable.sort(function (a, b) {
        var x = a[1][sortedBy].toLowerCase(),
          y = b[1][sortedBy].toLowerCase();
        return x < y ? reversed * -1 : x > y ? reversed : 0;
      });
    return sortable; // array in format [ [ key1, val1 ], [ key2, val2 ], ... ]
  }
*/
  return (
    <div id="content">
      <div className="container-fluid">
        <div className="container">
          <h3 className="text-center">Messages</h3>
          <div className="messaging">
            <div className="inbox_msg">
              <div className="inbox_people">
                <div className="headind_srch">
                  <div className="recent_heading">
                    <h4>Contacts</h4>
                  </div>
                  <div className="srch_bar">
                    <div className="stylish-input-group">
                      <input
                        type="text"
                        className="search-bar"
                        placeholder=""
                      />
                      <span className="input-group-addon">
                        <button type="button">
                          <i className="fa fa-search" aria-hidden="true" />
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="inbox_chat">
                  {/*
                  <div className="chat_list active_chat">
                    <a href="#">
                      <div className="chat_people">
                        <div className="chat_img">
                          <img
                            src="https://bootdey.com/img/Content/avatar/avatar1.png"
                            alt="sunil"
                          />
                        </div>
                        <div className="chat_ib">
                          <h5>Sunil Rajput </h5>
                        </div>
                      </div>
                    </a>
                                  </div>
                                  */}
                  {listMsg
                    ? listMsg.map((msg, i) => {
                        return (
                          <div className="chat_list" key={i}>
                            <a href={`/mymessage/${i}`}>
                              <div className="chat_people">
                                <div className="chat_img">
                                  <img src="https://firebasestorage.googleapis.com/v0/b/kabakaba-v2.appspot.com/o/imgforsite%2Fnoprofile.png?alt=media&token=7e52d942-0848-44c6-a534-086a391a2c36" />
                                </div>
                                <div className="chat_ib">
                                  <h5>
                                    {msg.users[0] === userId
                                      ? msg.User2Name
                                      : msg.User1Name}
                                  </h5>
                                </div>
                              </div>
                            </a>
                          </div>
                        );
                      })
                    : ""}
                  {/*
                  <div className="chat_list">
                    <a href="#">
                      <div className="chat_people">
                        <div className="chat_img">
                          <img
                            src="https://bootdey.com/img/Content/avatar/avatar2.png"
                            alt="sunil"
                          />
                        </div>
                        <div className="chat_ib">
                          <h5>Sunil Rajput </h5>
                        </div>
                      </div>
                    </a>
                  </div>

                  <div className="chat_list">
                    <div className="chat_people">
                      <div className="chat_img">
                        <img
                          src="https://bootdey.com/img/Content/avatar/avatar7.png"
                          alt="sunil"
                        />
                      </div>
                      <div className="chat_ib">
                        <h5>Sunil Rajput</h5>
                      </div>
                    </div>
                  </div>
                  */}
                </div>
              </div>
              <div className="mesgs">
                {message ? (
                  <>
                    <MessageBuble userId={userId} message={message} />
                  </>
                ) : (
                  ""
                )}
                <form>
                  <div className="type_msg">
                    <div className="input_msg_write">
                      <input
                        type="text"
                        className="write_msg"
                        placeholder="Votre message"
                        name="msgout"
                        value={msgout}
                        onChange={(e) => setMsgout(e.target.value)}
                        onKeyDown={(e) => something(e)}
                      />
                      <button
                        className="msg_send_btn"
                        type="button"
                        onClick={(e) => sendMessage(e)}
                      >
                        <i className="fa fa-paper-plane" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
