import React, { useState, useEffect } from "react";
import Firebase from "../../firebase";
import Adsline from "../general/adsline";
import LazyLoad from "react-lazyload";

const ListCategoryAds = (props) => {
  const [listAds, setlistAds] = useState();
  //const [image, setimage] = useState();

  useEffect(() => {
    if (props.match.params.category) {
      Firebase.GetAdsWithCategory(props.match.params.category).then(
        (result) => {
          setlistAds(result);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, []);

  return (
    <div id="content">
      <div className="container-fluid">
        {listAds
          ? listAds.map((ads, i) => {
              return (
                <LazyLoad once={ads} key={i} debounce={500} height={100}>
                  <Adsline key={i} ads={ads} />
                </LazyLoad>
              );
            })
          : ""}
      </div>
    </div>
  );
};

export default ListCategoryAds;
