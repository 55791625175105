import React, { useState, useEffect } from "react";
import Firebase from "../../firebase";
import Adsline from "../general/adsline";

const ListAdminAds = () => {
  const [listAds, setlistAds] = useState();
  const [isActive, setisActive] = useState(false);

  useEffect(() => {
    Firebase.GetAdsForAdminUser().then(
      (result) => {
        setlistAds(result);
      },
      (error) => {
        console.log(error);
      }
    );
  }, [isActive]);

  useEffect(() => {
    console.log("listAds", listAds);
  }, [listAds]);

  function MakeActive(value) {
    Firebase.MakeActivAds(value);
    setisActive(!isActive);
  }

  return (
    <div id="content">
      <div className="container-fluid">
        {listAds
          ? listAds.map((ads, i) => {
              return (
                <Adsline
                  key={i}
                  ads={ads}
                  MakeActive={MakeActive}
                  adminbutton={true}
                />
              );
            })
          : ""}
      </div>
    </div>
  );
};

export default ListAdminAds;
