import React, { useRef, useEffect } from "react";

function MessageBuble(props) {
  const { userId, message } = props;
  // console.log("Buble Msg", message);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  };

  useEffect(scrollToBottom, [message]);

  function outgoing(title) {
    return (
      <div className="outgoing_msg" key={Math.floor(Math.random() * 1000)}>
        <div className="sent_msg">
          <p>{title}</p>
        </div>
      </div>
    );
  }

  function incoming(title) {
    return (
      <div className="incoming_msg" key={Math.floor(Math.random() * 100)}>
        <div className="received_msg">
          <div className="received_withd_msg">
            <p>{title}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="msg_history">
      {message
        ? message.map((msg, i) => {
            if (userId == msg.author) {
              return outgoing(msg.body);
            } else {
              return incoming(msg.body);
            }
          })
        : ""}
      <div ref={messagesEndRef} />
    </div>
  );
}

export default MessageBuble;
