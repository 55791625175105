import React, { useState, useEffect } from "react";
import Firebase from "../../firebase";

function ChangePassword() {
  const [password, setPassword] = useState("");
  const [repassword, setRepassword] = useState("");
  const [error, seterror] = useState("");
  const [success, setsuccess] = useState("");

  useEffect(() => {}, []);

  function register() {
    seterror("");
    if (password === "" || repassword === "") {
      seterror("Remplissez tous les champs");
      return;
    }

    if (password.length < 6) {
      seterror("Le mot de passe doit être d'au moins 6");
      return;
    }

    if (password !== repassword) {
      seterror("Les mots de passe ne sont pas égaux");
      return;
    }
    try {
      Firebase.changePassword(repassword);

      setsuccess("processus terminé....");
    } catch (error) {
      seterror(error.message);
    }
  }
  return (
    <div id="content">
      <div className="container-fluid">
        <br />
        <div className="loginsignup p-3">
          <h2 className="text-center pb-1"> Changer le mot de passe</h2>
          <div className="loginbox-social"></div>
          <form onSubmit={(e) => e.preventDefault()}>
            {error !== "" ? (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            ) : (
              ""
            )}
            {success !== "" ? (
              <div className="alert alert-success" role="alert">
                {success}
              </div>
            ) : (
              ""
            )}
            <div className="form-group">
              <label>Nouveau Mot de passe</label>
              <input
                type="password"
                className="form-control"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Nouveau Mot de passe encore</label>
              <input
                type="password"
                className="form-control"
                name="repassword"
                value={repassword}
                onChange={(e) => setRepassword(e.target.value)}
              />
            </div>
            <button type="submit" className="btn btn-danger" onClick={register}>
              Changer le mot de passe
            </button>
          </form>
        </div>
        <br />
      </div>
    </div>
  );
}

export default ChangePassword;
