import React, { useEffect } from "react";
import Firebase from "../../firebase";

const User = () => {
  useEffect(() => {
    Firebase.GetAdsWithUser();
  }, []);

  return (
    <div>
      Name : {Firebase.getUsernameWithId(Firebase.getTokenWithExpiry())}
    </div>
  );
};

export default User;
